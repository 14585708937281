<template>
 <v-container fluid style="padding-bottom:80px">
    <v-row class="mb-2">
        <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-0">
                    <v-card-text>
                        <v-container fluid>
                            <v-row>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Mill</h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="mill"
                                        :items="mills"
                                        item-value="mill_id"
                                        :item-text="item => item.mill_id +' - '+ item.mill_name.trim()"
                                        class="border-12"
                                        hide-details=true
                                        @change="(event) => getMachType(event)"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Machine Type </h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="mach_type"
                                        :items="mach_types"
                                        item-value="mach_type"
                                        :item-text="item => item.mach_type +' - '+ item.descr.trim()"
                                        class="border-12"
                                        hide-details=true
                                        @change="(event) => [getMachID(event), getMaintenanceSection(event)]"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Machine ID </h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="mach_id"
                                        :items="mach_ids"
                                        item-value="mach_id"
                                        :item-text="item => item.mach_id +' - '+ item.descr.trim()"
                                        class="border-12"
                                        hide-details=true
                                        @change="(event) => getMaintenanceSection(event)"
                                        :loading="loading_section"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Maint. Section </h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="main_section"
                                        :items="main_sections"
                                        item-value="section_id"
                                        :item-text="item => item.section_id +' - '+ item.descr.trim()"
                                        class="border-12"
                                        hide-details=true
                                        :disabled="disable_sectid"
                                        :loading="isLoadingMainSection"
                                        @change="(event) => getPartMachine(event)"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Part List </h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="part_mach"
                                        :items="part_machs"
                                        item-value="part_id"
                                        :item-text="item => item.part_id +' - '+ item.descr.trim()"
                                        class="border-12"
                                        hide-details=true
                                        @change="(event) => List(event)"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Check List</h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="list"
                                        :items="lists"
                                        item-value="check_id" 
                                        item-text="check_descr" 
                                        class="border-12"
                                        hide-details=true
                                    ></v-autocomplete>
                                    <!-- :item-text="item => item.text" -->
                                </v-col> 
                                <v-col class="col-6" sm="6" md="2">
                                    <h6 class="blue-lcd mb-2" style="font-weight:600">Start Date </h6>
                                    <v-menu 
                                        ref="modal_start"
                                        v-model="modal_start"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            solo
                                            dense
                                            clearable
                                            v-model="datestart"
                                            label="Start Date"
                                            persistent-hint
                                            append-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="datestart"
                                            no-title
                                            @input="[modal_start = false]"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col class="col-6" sm="6" md="2">
                                    <h6 class="blue-lcd mb-2" style="font-weight:600">End Date </h6>
                                    <v-menu
                                        ref="modal_to"
                                        v-model="modal_to"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            solo
                                            dense
                                            clearable
                                            v-model="dateto"
                                            label="Date To"
                                            persistent-hint
                                            append-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="dateto"
                                            no-title
                                            @input="[modal_to = false]"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="2" class="col-12">
                                    <v-btn class="mt-7 border-12 ml-2 mr-2" color="secondary" @click="[clear()]"><v-icon class="mr-1">mdi-backspace</v-icon>Clear</v-btn>
                                    <v-btn class="mt-7 border-12" style="padding: 19px;" medium color="primary" elevation="2" @click="getDashboard()"><v-icon class="mr-1">mdi-magnify</v-icon>Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-card  class="pa-md-5">
                                <h5 style="font-weight: 900;">Maintenance Trend</h5>
                                <h6 class="cyan-text text-darken-3 m-0">By Date To Date</h6>
                                <div id="chartContainer1" style="height: 370px; width: 100%;"></div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
    </v-row>
 </v-container>
</template>
<script>
import {backendApi} from "@/backend-api/backend-api-sr"
export default {
    data(){
        return{
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'KKA',
                disabled: false,
                href: '/admin/kka',
                },
                {
                text: 'Maintenance',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Maintenance Trend',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            mill: '',
            mach_type:'',
            mach_id:'',
            main_section:'',
            part_mach:'',
            check:'',
            list:'',
            mills:[],
            mach_types:[],
            mach_ids:[],
            main_sections:[],
            part_machs:[],
            checks:[],
            lists:[],
            chartContainer1:[],
            modal_start: false,
            datestart: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal_to: false,
            dateto: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            loading_section:false,
            isLoadingMainSection: false,
            disable_sectid: false,
        }
    },
    async mounted(){
        // this.$store.dispatch('setOverlay', true)
        // await this.isMobileFunc()
         await this.getMill()
         await this.getMachType('KKA')
        // await this.getMachID('SR')
         await this.getMaintenanceSection('KKA')
        // this.List()
        // await this.getPartMachine('SR')
        // await this.getEnvConf()
        // this.$store.dispatch('setOverlay', false)
    },
    methods:{
    clear(){
            this.mach_id = ''
            this.main_section = ''
            this.part_mach = ''
            this.date = ''
            this.list = ''
    },
    monthNumToName(monthnum) {
            return this.months[monthnum - 1] || '';
        },
    isMobileFunc() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },

    async getMill(){
            const respData = await backendApi.fetchCore(`/v2/kka/maintenance/mill?mill_id=${this.mill ? this.mill.trim() : ''}`, null, false, false, false) 
            if (respData.status === 200) {
                this.mills = respData.data.data
            }
        },

    async getMachType(event){
            if (event === '' || event === null) {
                this.mach_type = ''
            } else {
                const respData = await backendApi.fetchCore(`/v2/kka/maintenance/mach-type?mill_id=${this.mill ? this.mill.trim() : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.mach_types = respData.data.data
                }
            }
        },

    async getMachID(event){
            if (event === '' || event === null) {
                this.mach_id = ''
            } else {
                const respData = await backendApi.fetchCore(`/v2/kka/maintenance/mach-id?mill_id=${this.mill ? this.mill.trim() : ''}&mach_type=${this.mach_type ? this.mach_type : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.mach_ids = respData.data.data
                }
            }
        },
    
    async getMaintenanceSection(event){
            if (event === '' || event === null) {
                this.main_section = ''
            } else {
                this.loading_section = true
                this.main_sections = []
                const respData = await backendApi.fetchCore(`/v2/kka/maintenance/maint-section?mill_id=${this.mill ? this.mill.trim() : ''}&mach_type=${this.mach_type ? this.mach_type : ''}&mach_id=${this.mach_id ? this.mach_id : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.loading_section = false
                    this.main_sections = respData.data.data
                }
            }
        },

    async getPartMachine(event){
            if (event === '' || event === null) {
                this.part_mach = ''
            } else {
                this.isLoadingMainSection = true
                this.part_machs = []
                const respData = await backendApi.fetchCore(`/v2/kka/maintenance/part-of-mach?mill_id=${this.mill ? this.mill.trim() : ''}&section_id=${this.main_section ? this.main_section : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.part_machs = respData.data.data
                    this.isLoadingMainSection = false
                }
            }
        },

    async List(){
        await axios.get(`${process.env.VUE_APP_URL}/v2/kka/maintenance_trend/filterlist?search=${this.$store.state.text}&mill_id=${this.mill ? this.mill : ''}&section_id=${this.main_section ? this.main_section : ''}&part_id=${this.part_mach ? this.part_mach : ''}`, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
          //console.log(res.data)
          this.lists = res.data.datanyamase
           
        })   
            
    }, 
    async getDashboard(){
            if (this.mach_type === '' || this.mach_type === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert Machine Type !",
                    visible: true
                    };
                return false;
            }
            if (this.mach_id === '' || this.mach_id === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert Machine ID !",
                    visible: true
                    };
                return false;
            }
            if (this.main_section === '' || this.main_section === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert Main Section ! ",
                    visible: true
                    };
                return false;
            }
            if (this.part_mach === '' || this.part_mach === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert Part List !  ",
                    visible: true
                    };
                return false;
            }
            if (this.list === '' || this.list === null) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Whoooopsss !",
                    text: "Please Insert Check List !   ",
                    visible: true
                    };
                return false;
            }
            await axios.get(`${process.env.VUE_APP_URL}/v2/kka/maintenance_trend/GetData?search=${this.$store.state.text}&mill_id=${this.mill ? this.mill : ''}&mach_type=${this.mach_type ? this.mach_type : ''}&mach_id=${this.mach_id ? this.mach_id : ''}&section_id=${this.main_section ? this.main_section : ''}&part_id=${this.part_mach ? this.part_mach : ''}&check_id=${this.list ? this.list : ''}&dateFrom=${this.datestart ? this.datestart : ''}&dateTo=${this.dateto ? this.dateto : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.dashboardContent = res.data.datanyamase
               // console.log(this.dashboardContent)

                var dataPoints = res.data.datanyamase.map(item => {
                        var check_result = parseFloat(item.check_result);
                        return { label: item.tgl, y: check_result };
                    });    
                 
                  

                    this.getChart(dataPoints);
                

                this.$store.dispatch('setOverlay', false)

            })
        }, 

    getChart(dataPoints, ){
        console.log(dataPoints)
            var chart = new CanvasJS.Chart("chartContainer1", {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                axisY: [
                    {
                        crosshair: {
                            enabled: true,
                            snapToDataPoint: true
                        },
                        title: "Result",

                    },
                ],
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                
                data: [
             
                {
                    type: "line",
                    indexLabel: "{y}",
                    name: 'Result',
                    indexLabelFontSize: 12,
                    color:"#1C1DBB",
                    showInLegend: true,
                    indexLabelPlacement: "inside",
                    indexLabelOrientation: "vertical",
                    yValueFormatString: "###,###",
                    indexLabelFontColor: "white"
                  
                },]
            });

            chart.options.data[0].dataPoints = dataPoints;
            chart.render();
        },     

    }

}
</script>

<style>

</style>